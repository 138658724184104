import './App.css';

function App() {

  var name = process.env.NAME; // '42348901293989849243'
  console.log("name", name)
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Hello, From ponds-terxr tenant!
        </h1>
      </header>
    </div>
  );
}

export default App;
